.custom-listing {
  text-align: justify;
  margin: 0;
  list-style: none;
  li {
    position: relative;
    &::before {
      content: "";
      background: url(../../assets/icons/custom-list.svg) center no-repeat;
      position: absolute;
      top: -webkit-calc(50% - 18px);
      top: calc(50% - 18px);
      left: -40px;
      width: 36px;
      height: 36px;
    }
    &::marker {
      display: none;
    }
  }
}

.heart-listing {
  text-align: justify;
  margin: 0;
  list-style: none;
  li {
    position: relative;
    &::before {
      content: "";
      background: url(../../assets/icons/heart.svg) center no-repeat;
      position: absolute;
      top: -webkit-calc(50% - 18px);
      top: calc(50% - 18px);
      left: -40px;
      width: 36px;
      height: 36px;
    }
    &::marker {
      display: none;
    }
  }
}

@import "../../../assets/styles/styles";

.contact-list {
  overflow: "auto";
  max-height: 800px;
  height: 800px;

  &__item {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid;
    border-radius: 10px;
    color: black;
    font-size: 16px;
    color: $gray20;

    &.unread {
      background-color: antiquewhite;
    }

    &:hover {
      cursor: pointer;
      background-color: orange;
    }
  }
}
@import "../../../assets/styles/styles";

.navbar {
  background-color: $gray;
  border-color: $dark-gray;
  min-height: 50px;
  margin-bottom: 0;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.fixed {
    top: 0;
    border-width: 0 0 1px;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
  }

  &__logo {
    display: flex;
    float: left;
    height: 50px;
    font-size: 18px;
    line-height: 20px;

    img {
      width: 100px;
      display: block;
    }
  }

  &__small-nav {
    display: none;
  }

  &__nav {
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      margin: 0;
    }

    &__link {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 10px 15px;
      color: $white  !important;
      text-decoration: none !important;
      font-family: 'Avenir';
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      line-height: 22px;
      text-transform: uppercase;

      .icon {
        width: 9px;
        height: 5px;
        margin-left: 2px;
        margin-bottom: 4px;
      }

      &:hover {
        color: $theme-orange  !important;

        .icon {
          filter: invert(41%) sepia(98%) saturate(1817%) hue-rotate(360deg) brightness(100%) contrast(107%);
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .navbar {
    &__logo {
      position: absolute;
      align-self: flex-start;
    }

    &__nav {
      display: none;
    }

    &__small-nav {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;

      .menu {
        width: 100%;
        margin-top: 55px;
      }

      .main-button {
        position: absolute;
        top: 16px;
        right: 10px;
      }
    }
  }
}
@import "../../assets/styles/styles";

.image-picker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;

  &__image-name-container {
    position: relative;
    width: 100%;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    padding: 0.67857143em 1em;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 0 0 0 transparent inset;
    transition: color 0.1s ease, border-color 0.1s ease;
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    margin: 0;

    .image-name {
      color: rgba(0, 0, 0, 0.87);
      font-size: 1em;
      line-height: 1.21428571em;
    }

    .placeholder {
      color: rgba(0, 0, 0, 0.6);
      font-size: 1em;
      line-height: 1.21428571em;
    }
  }

  &__button {
    border: none;
    border-radius: 14px;
    width: 250px;
    height: 46px;
    cursor: pointer;
    position: relative;

    &--disabled {
      opacity: 0.5;
      border: none;
      border-radius: 14px;
      width: 250px;
      height: 46px;
    }

    &:hover {
      opacity: 0.7;
    }

    &__text {
      margin: 0px;
      color: $white;
      font-size: 18px;

      &--loading {
        display: none;
      }
    }
  }
}

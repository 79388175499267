@media (max-width: 599px) {
  .news {
    margin-top: 50px;

    a {
      display: flex;
      justify-content: center;
    }
  }
}

.tags {
  margin-top: 7em;

  &__title {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #333333;
  }

  .add-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;

    &__input {
      height: 40px;
      width: 200px;
      border: 0px;
      border-bottom: 2px solid #ff6600;
      font-size: 18px;
      line-height: 19px;
      padding: 8px 10px;
    }

    &__button {
      border: 0px;
      border-radius: 20px;
      padding: 10px 40px;
      background: #ff6600;
      align-self: flex-start;
      width: 200px;

      &:hover {
        opacity: 0.5;
      }

      &__text {
        color: white;
        font-size: 18px;
      }
    }
  }
}

.news {
  display: flex;
  flex-direction: column;
  padding: 40px;

  &__image {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    max-width: 505px;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0px;

    .main-tag {
      font-weight: 400;
      font-size: 17px;
      line-height: 19px;
      color: orange;
      cursor: pointer;
      text-align: start;
      margin-bottom: 0px;
    }

    .divider {
      font-size: 19px;
      color: grey;
      margin-bottom: 0px;
    }

    .date {
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      color: rgba(0, 0, 0, 0.87);
      text-align: start;
      margin: 15px 0 11px 0;
    }
  }

  &__description {
    .title {
      cursor: pointer;
      padding-right: 20px;
      margin-bottom: 10px;
      margin-top: 0px;
      font-weight: 400;
      font-size: 18.9341px;
      line-height: 26px;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.87);

      &:hover {
        text-decoration: underline;
      }
    }

    .about {
      padding-right: 20px;
      margin-top: 0;
      font-weight: 400;
      font-size: 15.7784px;
      line-height: 25px;
      color: rgba(0, 0, 0, 0.67);
    }
  }

  &__labels {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding-right: 50px;
    gap: 10px;
    margin-top: 20px;

    .label {
      cursor: default;
      width: 100%;
      margin: 0px;
      padding-bottom: 10px;
      padding-top: 10px;
      border: 1px solid rgba(253, 151, 50, 0.38);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

@media (max-width: 1199px) {
  .news {
    &__info {
      margin-top: 20px;
    }
  }
}

@media (max-width: 599px) {
  .news {
    padding: 0;

    &__description {
      padding-right: 0px;

      .title {
        padding-right: 0px;
      }
    }

    &__image {
      width: 100%;
    }

    &__info {
      margin-top: 0px;
    }

    &__labels {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 10px;
      padding-right: 0px;
    }
  }
}

@import "../../assets/styles/styles";

.page {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  &__content {
    flex: 1;
    box-sizing: border-box;
  }

  &__footer {
    flex: 0;
    box-sizing: border-box;
  }
}

@media screen and (min-width: $small-screen-breakpoint) {
  .page {
    min-height: 100vh;

    &__content {
      margin-top: 50px;
      min-height: calc(100vh - 50px);
    }

    &__footer {
      box-sizing: border-box;
    }
  }
}

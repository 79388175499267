.tags-list {
  max-height: 300px;
  overflow-y: scroll;
  padding-left: 12px;

  &__inactive {
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    cursor: pointer;
    margin: 20px 0px;
  }

  &__active {
    border: 1px solid #ff6600;
    border-radius: 10px;
    padding: 6px 10px;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
  }
}

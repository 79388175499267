@import "../../../assets/styles/styles";

.offers {
  margin-top: 100px;
  margin-bottom: 150px;

  .offers-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      padding: 20px 80px !important;
      font-size: 35px !important;
      border-radius: 30px;
    }

    &__offers-grid {
      display: grid;
      grid-template-columns: repeat(2, minmax(0px, 450px));
      gap: 70px;
      margin-bottom: 100px;

      &__offer {
        display: flex;
        flex-direction: column;
        height: 182px;
        background: $white-smoke;
        box-shadow: 0px 3.37095px 3.37095px rgba(0, 0, 0, 0.25);
        border-radius: 15px;

        &:hover {
          background: linear-gradient(242.93deg, #FE912C -0.05%, #FF720C 98.64%);

          .title {
            color: $white;

            &__img {
              display: block;
            }
          }

          .description {
            color: $white;
          }
        }

        .title {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          padding: 0px 40px;
          height: 50%;
          font-weight: 800;
          font-size: 25.9px;
          line-height: 35px;
          color: $gray20;

          &__img {
            display: none;
            padding: 7px;
          }
        }

        .description {
          padding: 10px 40px;
          height: 50%;
          font-weight: 400;
          font-size: 16.33px;
          line-height: 22px;
          color: $theme-gray;
        }
      }
    }
  }
}

@media (max-width: 999px) {
  .offers {
    .offers-block {
      &__offers-grid {
        grid-template-columns: repeat(1, minmax(0px, 450px));
      }
    }
  }
}
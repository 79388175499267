@import "../../assets/styles/styles";

.job-offer-form {
  display: flex;
  align-items: center;
  justify-content: center;

  &__input-container {
    display: flex;
    width: 95%;
    margin: 20px;
  }

  &__input {
    margin-bottom: 20px;
  }

  &__new-field-button {
    display: flex;
    width: 90%;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
  }

  &__control-buttons {
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: row;
  }
}
$white:                              #fff;
$black:                              #000;
$gray:                               #222222;
$dark-gray:                          #090909;
$maire:                              #2E2F29;
$orange-peel:                        #FA9E00;
$nero:                               #2B2B2B;
$davys-grey:                         #5e5e5b;
$black-mana:                         #868686;
$gray20:                             #333333;
$gray90:                             #E5E5E5;
$neon-carrot:                        #FD9732;
$white-smoke:                        #F3F3F3;
$cultured:                           #F4F4F4;
$error:                              #cc5951;
$theme-background-gray:              #dadada;
$theme-background-transparent:       rgba(60, 61, 49, 0.5);
$theme-background-transparent-dark:  rgba(60, 61, 49, 0.75);
$theme-background-dark:              rgba(46, 47, 41, 1);
$theme-blog-background:              rgba(255, 255, 255, 1);
$theme-box-shadow:                   rgba(0, 0, 0, 0.3);
$theme-breadcrumb-text-color:        #595959;
$theme-dropdown-text-gray:           rgba(94, 94, 91, 1);
$theme-dropdown-text-orange:         rgba(223, 89, 31, 1);
$theme-dropdown-text-yellow:         rgba(199, 207, 86, 1);
$theme-dropdown-text-white:          rgba(218, 218, 218, 1);
$theme-footer-dark:                  #818181;
$theme-footer-light:                 #c1c1c1;
$theme-footer-socials:               #4b4b4b;
$theme-orange:                       #ff6600;
$theme-gray:                         #666666;
$theme-tint:                         rgba(0, 0, 0, 0.4);
$theme-yellow:                       #afb349;
$theme-yellow-light:                 #dee3a1;
$theme-yellow-light-dim:             rgba(247, 252, 178, 0.5);
$theme-yellow-custom-navbar:         #d1da59;
.blog {
  margin-top: 5em;
  margin-bottom: 25em;

  &__datepicker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    .datepicker {
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
      padding: 0.67857143em 1em;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.87);
      font-size: 1em;
      line-height: 1.21428571em;
      background: #fff;
      box-shadow: 0 0 0 0 transparent inset;
      transition: color 0.1s ease, border-color 0.1s ease;
      font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;

      &:focus {
        outline: none !important;
        border: 1px solid #85b7d9;
      }
    }
  }

  &__dropdowns-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  &__blog-images-container {
    margin-bottom: 50px;
  }

  &__text-inputs {
    display: flex;
    flex-direction: column;
    width: 100%;

    .input {
      position: relative;
      width: 100%;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
      padding: 0.67857143em 1em;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.87);
      font-size: 1em;
      line-height: 1.21428571em;
      background: #fff;
      box-shadow: 0 0 0 0 transparent inset;
      transition: color 0.1s ease, border-color 0.1s ease;
      font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;

      &:focus {
        outline: none !important;
        border: 1px solid #85b7d9;
      }

      &::selection {
        background-color: rgba(100, 100, 100, 0.4);
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }

  &__control-button-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__editor {
    margin-bottom: 50px;
  }

  .wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
    position: relative;
  }
  .editor-class {
    background-color: whitesmoke;
    padding: 1rem;
    border: 1px solid #ccc;
  }
  .toolbar-class {
    border: 1px solid #ccc;
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    z-index: 999;
  }
}

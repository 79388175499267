.multi-select-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 100px;

  &__container {
    width: 300px;
  }
}

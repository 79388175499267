.text-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  &__input {
    position: relative;
    width: 100%;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    padding: 0.67857143em 1em;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1em;
    line-height: 1.21428571em;
    background: #fff;
    box-shadow: 0 0 0 0 transparent inset;
    transition: color 0.1s ease, border-color 0.1s ease;
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    margin-bottom: 20px;

    &:focus {
      outline: none !important;
      border: 1px solid #85b7d9;
    }

    &::selection {
      background-color: rgba(100, 100, 100, 0.4);
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
